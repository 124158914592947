html {
  overflow-x: hidden;
}

body {
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 2;
  letter-spacing: 0.01071em;
  background-color: #404040;
  overflow-x: hidden;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 11vh;
  width: 100vw;
  background: linear-gradient(#1a90ff, #cce6ff);
}

h2,
h3 {
  margin: 10px 10px 8px 10px;
  color: white;
}

p {
  color: white;
}

input,
select {
  cursor: pointer;
  margin: 2px;
}

input:focus {
  cursor: text;
}

input:invalid + span:after,
select:invalid + span:after {
  content: "✖";
  color: #8b0000;
}

input:valid + span:after,
select:valid + span:after {
  content: "✓";
  color: #009000;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: black;
  padding: 6px;
}

#TermsOfUseLink:link,
#TermsOfUseLink:visited {
  text-decoration: underline;
  color: rgb(146, 116, 255);
  padding: 1px;
}
#TermsOfUseLink:hover,
#TermsOfUseLink:active {
  color: rgb(203, 189, 255);
}

.errorMessage {
  color: red;
  display: flex;
  justify-content: center;
}

.gradient_Button,
.selected_type_button {
  min-width: 64px;
  margin: 3px 1px 10px 1px;
  padding: 6px 16px;
  border: 0;
  border-radius: 4px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background: linear-gradient(#1a90ff, #99ceff);
  cursor: pointer;
  outline: none;
}
.material-icons {
  background: linear-gradient(#1a90ff, #99ceff);
  border-radius: 4px;
  border: none;
  outline: none;
  content: "swap_vert";
}
.selected_type_button {
  background: #f5ffff;
  border: 1.7px solid #1a90ff;
}

.desktop-break {
  display: none;
}
.mobile-break {
  display: inline;
}
.addressDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 11px;
}
.cancel_label {
  color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#agree_chk_error {
  visibility: visible;
  color: red;
}
#agree_chk_no_error {
  visibility: hidden;
  color: red;
}

/*Navbar*/

.option a:hover {
  background-color: #cce6ff;
  border-radius: 10px;
  margin-right: 5px;
  color: #fff;
}

.active {
  background-color: #cce6ff;
  border-radius: 10px;
  margin-right: 5px;
  color: #fff;
}
.nav-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -100%;
  height: auto;
  margin: 0px;
  transition: all 0.5s ease;
  background: linear-gradient(#1a90ff, #cce6ff);
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vw;
  padding: 30px 0px;
}

.nav-options.active {
  top: 0;
  left: 0;
  z-index: 5;
  align-content: center;
  width: 100vw;
  height: auto;
  margin: 0px;
  padding: 1px;
  transition: all 0.5s ease;
  background: linear-gradient(#1a90ff, #cce6ff);
}

.mobile-menu {
  display: block;
  padding-right: 10px;
  margin-left: auto;
}

.menu-icon {
  position: absolute;
  top: 15px;
  right: 6px;
  z-index: 6;
  width: 45px;
  height: 45px;
}

/*Order Form*/

.orderForms {
  margin-top: 6px;
  border: 1px solid white;
}

.order {
  margin-top: 20px;
  width: 66vw;
}

.order_form,
.login_form,
.cancel_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}
.OrderType_buttons {
  display: flex;
  flex-direction: column;
}

label {
  margin: 6px;
}
#targetMunicipality,
#pickUpMunicipality {
  margin-bottom: 7px;
}
.Municipality_Active,
.showRides,
.noOrders {
  display: inline;
}

.Municipality_off,
.noRides,
.yesOrders,
.hide,
.isPassenger,
.nowOrder {
  display: none;
}

.show {
  display: block;
}

.notPassenger,
.timeOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rides {
  padding: 7px;
}
.ride {
  border-radius: 4px;
  width: 64vw;
  margin: 1px 1px 8px 4px;
  background-color: white;
  cursor: pointer;
}
.gradient_Button,
.selected_type_button {
  margin: 5px;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
.order_title {
  color: black;
}

@media only screen and (min-width: 635px) {
  /*Desktop view */
  /*General*/
  .App-header {
    width: 100vw;
  }
  a:link {
    padding: 2px 27px 1px 20px;
  }
  input:invalid + span:after,
  select:invalid + span:after {
    padding-left: 5px;
  }

  input:valid + span:after,
  select:valid + span:after {
    padding-left: 5px;
  }
  .desktop-break {
    display: inline;
  }

  .mobile-break {
    display: none;
  }

  .gradient_Button,
  .selected_type_button {
    margin: 19px 1px 12px 1px;
  }

  /*Navbar*/
  .mobile-option {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .option {
    height: 6vh;
  }
  .nav-options {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: static;
    height: 100px;
  }
  /*Order form*/
  .orderForms {
    margin-top: 6px;
  }
  .order,
  error-display {
    margin-top: 20px;
  }
  label {
    margin: 6px;
  }
  .order_form {
    display: block;
    margin: 4px 12px 4px 3px;
    text-align: center;
  }
  .login_form {
    display: block;
    margin: 4px 12px 4px 3px;
    text-align: center;
  }

  .OrderType_buttons {
    display: block;
  }
  .gradient_Button,
  .selected_type_button {
    margin: 5px;
  }
  .cancel_form {
    display: block;
    margin: 4px 12px 4px 3px;
    text-align: center;
  }
  #targetMunicipality,
  #pickUpMunicipality {
    margin-right: 7px;
  }
  .checkboxes {
    margin-left: 6px;
  }
  .notPassenger,
  .timeOrder {
    display: block;
  }
  .ride {
    text-align: left;
    padding: 3px;
    margin: 1px 7px 10px 6px;
  }
  .addressDiv {
    flex-direction: row;
  }
}
